<template>
  <div id="callback" />
</template>

<script>
/**
 * Callback
 *
 * This component is rendered when the user is redirected
 * back to the client application after a successful login
 * attempt.
 *
 * User will be redirected to the Dashboard by default,
 * unless a redirect path has been set in the store.
 */
import Mixpanel from 'mixpanel-browser'
import { mapState } from 'vuex'

export default {
  name: 'Callback',
  computed: mapState({
    redirectPath: state => state.auth.redirect,
    account: state => state.auth.account
  }),
  async created () {
    try {
      await this.$store.dispatch('auth/createAuthSession')
      await this.$store.dispatch('auth/fetchAccount')

      if (process.env.NODE_ENV === 'production' && this.account && !this.account.is_staff) {
        Mixpanel.identify(this.account.id)
        Mixpanel.track('Login')
        Mixpanel.people.set({
          $distinct_id: this.account.id,
          $email: this.account.email,
          $name: this.account.name
        })
      }

      // Restore path
      let location = { name: 'Home' }
      if (this.redirectPath) {
        location = { path: this.redirectPath }
      }

      this.$router.push(location)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        alert(error)
      }
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
